import React from 'react'
import Layout from 'components/layout/layout'
import MembershipForm from 'components/forms/membership'

export default () => {
  return (
    <Layout>
      <MembershipForm />
    </Layout>
  )
}
